import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Sidebar as PulseSideBar, Flex } from '@ubisend/pulse-components';
import { motion } from '@ubisend/framer-motion';

const StyledContainer = styled(Flex)`
  padding-right: 1.5rem;
  z-index: 20;
`;

const colour = () => {
  return 'black';
};

const hoverColour = ({ isActive = false }) => {
  if (isActive) {
    return '';
  }

  return `rgba(0,0,0,0.5);`;
};

const backgroundColour = ({ isActive = false }) => {
  if (!isActive) {
    return '';
  }

  return 'rgba(0,0,0,0.1)';
};

const styles = css`
  ${tw`no-underline text-xs box-border list-reset flex flex-wrap items-center flex flex-row px-4 py-2 rounded w-full`};
  transition: color ease-in-out 0.1s, background-color ease-in-out 0.1s;
  color: ${colour};
`;

const hoverStyles = css`
  &:hover {
    color: ${hoverColour};
  }
`;

const activeStyles = css`
  background-color: ${backgroundColour};
`;

const StyledLink = styled(motion.div)`
  ${tw`w-full`}

  & > a {
    ${tw`uppercase cursor-pointer`}
    ${styles}
    ${activeStyles}
    ${hoverStyles}
  }

  & > button {
    ${tw`uppercase border-0 m-0 outline-none bg-transparent cursor-pointer`}
    ${styles}
    ${activeStyles}
    ${hoverStyles}
  }

  & > span {
    ${tw`uppercase border-0 m-0 outline-none bg-transparent`}
    ${styles}
  }
`;

const links = [
  {
    label: 'Main',
    sectionToggle: 'main'
  },
  {
    label: 'Engagement',
    sectionToggle: 'engagement'
  },
  {
    label: 'Design',
    sectionToggle: 'design'
  },
  {
    label: 'Advanced',
    sectionToggle: 'advanced'
  }
];

const SideMenu = ({ settingSection, handleClick }) => {
  return (
    <StyledContainer>
      <PulseSideBar ptNone width="197px" height="auto" type="owner">
        {links.map((link, key) => (
          <StyledLink
            isActive={settingSection === link.sectionToggle}
            key={key}
            onClick={() => {
              handleClick(link.sectionToggle);
            }}>
            <Link>{link.label}</Link>
          </StyledLink>
        ))}
      </PulseSideBar>
    </StyledContainer>
  );
};

SideMenu.propTypes = {
  settingSection: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired
};

export default SideMenu;
