import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';

import {
  PageWrapper,
  Panel,
  Button,
  Flex,
  Explainer,
  LeftHalfLayout,
  RightHalfLayout,
  Label,
  Toggle
} from '@ubisend/pulse-components';
import { AppComposerWidget } from '@ubisend/pulse-bot';
import {
  MultiComposerProvider,
  MultiComposerSettings,
  MultiComposer,
  MultiComposerSubmitButton,
  MultiComposerWidget,
  MultiComposerLanguageSelect
} from '@ubisend/pulse-composer';
import { PermissionFilter } from '@ubisend/pulse-auth';

import subjects from '../subjects/index';
import { getResponses, getFaq, syncResponses } from '../api/index';

const notification = {
  type: 'success',
  message: 'Successfully updated your FAQ responses.'
};

const Responses = ({
  match: {
    params: { faqId }
  }
}) => {
  const [responses, setResponses] = useState();
  const [faq, setFaq] = useState();
  const [randomResponse, setRandomResponse] = useState();

  const { state } = useLocation();
  const history = useHistory();

  const fetchResponses = useCallback(async () => {
    const [responses, faq] = await Promise.all([
      getResponses(faqId),
      getFaq(faqId)
    ]);

    setResponses(responses.data.data);
    setRandomResponse(faq.data.data.should_use_random_response);
    setFaq(faq.data.data);
  }, [faqId]);

  useEffect(() => {
    fetchResponses();
  }, [fetchResponses]);

  const handleRandomResponseChange = () => {
    setRandomResponse(randomResponse => !randomResponse);
  };

  const handleSubmit = responses => {
    return syncResponses(faqId, {
      ...responses,
      should_use_random_response: randomResponse
    });
  };

  const handleSubmitAndNotCreateAnother = async responses => {
    await handleSubmit(responses);

    history.push('/faqs', { notification });
  };

  const handleSubmitAndCreateAnother = async responses => {
    await handleSubmit(responses);

    history.push('/faqs/create', { notification });
  };

  const loaded = Boolean(faq && responses);
  const inCreationFlow = Boolean(state?.creatingFaq);

  const generateHeader = () => {
    if (!loaded) {
      return 'Answers';
    }

    if (!faq?.location) {
      return `Answers for ${faq?.name}`;
    }

    return `Answers for ${faq?.name} (${faq.location.name})`;
  };

  return (
    <PageWrapper
      header={generateHeader()}
      subheader="Write the answer to this FAQ"
      actions={
        <PermissionFilter can="edit faqs">
          <Button as={Link} variant="secondary" to={`/faqs/${faqId}/questions`}>
            Questions
          </Button>
        </PermissionFilter>
      }>
      {loaded && (
        <MultiComposerProvider
          initialResponses={responses}
          subjects={subjects.composers.subjects}>
          <Flex>
            <LeftHalfLayout>
              <Panel
                header="Composer your answer"
                actions={<MultiComposerLanguageSelect />}>
                <Explainer mb mt>
                  {faq.should_use_random_response
                    ? 'This FAQ has random responses enabled. This means when a subscriber asks an FAQ, the chatbot will choose a random response in the correct language to respond with.'
                    : 'This FAQ does not have random responses enabled. When asks this question, the chatbot will respond with all messages below.'}
                </Explainer>
                <Flex between center mb>
                  <Flex>
                    <Label mb={false}>Random Responses</Label>
                  </Flex>
                  <Toggle
                    on={randomResponse}
                    handleToggleChange={handleRandomResponseChange}
                  />
                </Flex>
                <MultiComposer />
                <Flex xSpace fat between>
                  <MultiComposerSettings />
                  <Flex xSpace center>
                    {inCreationFlow && (
                      <MultiComposerSubmitButton
                        onClick={handleSubmitAndCreateAnother}>
                        Save and create another
                      </MultiComposerSubmitButton>
                    )}
                    <Flex>
                      <MultiComposerSubmitButton
                        onClick={handleSubmitAndNotCreateAnother}>
                        Save
                      </MultiComposerSubmitButton>
                    </Flex>
                  </Flex>
                </Flex>
              </Panel>
            </LeftHalfLayout>
            <RightHalfLayout>
              <Flex fat middle>
                <MultiComposerWidget as={AppComposerWidget} />
              </Flex>
            </RightHalfLayout>
          </Flex>
        </MultiComposerProvider>
      )}
    </PageWrapper>
  );
};

export default Responses;
