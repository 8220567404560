import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import styles from './Types/styles';

const DisabledButton = styled.button`
  ${styles}
  cursor: not-allowed;
  filter: opacity(0.5);
  &:hover {
    background-color: inherit;
  }
`;

const DisabledActionButton = ({ response, ...props }) => (
  <DisabledButton {...props} colour="white" type="button" aria-disabled="true">
    {response.label}
  </DisabledButton>
);

DisabledActionButton.propTypes = {
  response: PropTypes.shape({
    label: PropTypes.string.isRequired
  }).isRequired
};

export default DisabledActionButton;
