import React from 'react';
import { Link } from 'react-router-dom';

import {
  PageWrapper,
  OneHalfLayout,
  Panel,
  Explainer,
  Flex,
  Label,
  TextInput,
  Button,
  Placeholder
} from '@ubisend/pulse-components';
import { useCopyToClipboard } from '@ubisend/pulse-component-hooks';
import { useQuery, useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import { PolicyAccept } from '@ubisend/pulse-channels';

import { acceptPolicy as acceptPolicyApi } from '../api/index';

const AlexaLink = () => {
  const query = useQuery('alexa/settings');

  const queryClient = useQueryClient();

  const { mutate: acceptPolicy } = useMutation(acceptPolicyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('alexa/settings');
    }
  });

  const { copy, copied } = useCopyToClipboard();

  const handleCopy = () => {
    copy(query.data.data.url);
  };

  const PolicyCheck = () => {
    if (!query.data.data.has_given_data_consent) {
      return (
        <PolicyAccept
          handlePolicyAccept={acceptPolicy}
          policyUrl="https://developer.amazon.com/support/legal/da"
        />
      );
    }

    return (
      <Panel divider header="Link your Alexa Skill">
        <Flex col>
          <Explainer mb>
            Copy the link below into your Alexa console. Follow our{' '}
            <Link to="/docs/2151317744/2153283818">documentation</Link> to set
            up your first Alexa chatbot.
          </Explainer>
          <Label htmlFor="api">API endpoint</Label>
          <Flex>
            <TextInput
              id="api"
              data-testid="alexa-setting-input"
              disabled
              value={query.data.data.url}
            />
            <Button
              disabled={copied}
              variant="primary"
              icon="clipBoard"
              onClick={handleCopy}>
              {copied ? 'Copied' : 'Copy'}
            </Button>
          </Flex>
        </Flex>
      </Panel>
    );
  };

  return (
    <PageWrapper center header="Alexa">
      <Flex col center>
        <OneHalfLayout>
          {query.isLoading && <Placeholder items={1} subitems={2} />}
          {query.isSuccess && <PolicyCheck />}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default AlexaLink;
