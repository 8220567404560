import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import chroma from 'chroma-js';

import { useTheme } from '../../hooks/index';

const ButtonSelect = ({ colour, minWidth = '10rem', ...props }) => {
  const theme = useTheme();
  const themeColour = theme[colour] || theme.primary;

  return (
    <Select
      {...(process.env.NODE_ENV !== 'test' && {
        menuPortalTarget: document.body
      })}
      styles={{
        menuPortal: styles => ({ ...styles, zIndex: 9999 }),
        control: () => ({
          boxSizing: 'border-box',
          color: themeColour,
          border: `1px solid ${chroma(themeColour).alpha(0.5)}`,
          minWidth,
          '&:hover': { backgroundColor: chroma(themeColour).alpha(0.05).css() },
          '&:active': { backgroundColor: chroma(themeColour).alpha(0.1).css() },
          ...tw`w-full flex rounded-sm uppercase py-1 cursor-pointer bg-white`
        }),
        dropdownIndicator: () => tw`flex px-2`,
        singleValue: styles => ({
          ...styles,
          color: themeColour,
          ...tw`font-poppins`
        }),
        valueContainer: styles => ({
          ...styles,
          ...tw`text-xs leading-tight tracking-wide cursor-pointer truncate`
        }),
        indicatorSeparator: () => null,
        clearIndicator: () => null,
        option: (styles, state) => {
          return {
            ...styles,
            boxShadow: 'none',
            background: state.isSelected
              ? themeColour
              : state.isFocused
              ? chroma(themeColour).alpha(0.25).css()
              : 'none',
            color: state.isSelected || state.isFocuesed ? 'white' : state.color,
            '&:hover': {
              background:
                state.isSelected || state.isFocuesed
                  ? themeColour
                  : chroma(themeColour).alpha(0.25).css(),
              color: state.isSelected || state.isFocuesed ? 'white' : 'black'
            }
          };
        }
      }}
      isSearchable={false}
      menuPlacement={'auto'}
      {...props}
    />
  );
};

ButtonSelect.propTypes = {
  colour: PropTypes.oneOf(['positive', 'warning', 'danger']),
  minWidth: PropTypes.string
};

export default ButtonSelect;
