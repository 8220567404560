import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { useQuery } from '@ubisend/pulse-hooks';
import {
  PanelSlider,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Flex,
  Span,
  Placeholder,
  useOrderableTableReducer,
  OrderableTableRow,
  usePaginationReducer,
  Pagination
} from '@ubisend/pulse-components';

const ResultWindow = ({ chosenTestId, handleHide }) => {
  const order = useOrderableTableReducer({ id: 'faq-test-results' });
  const pagination = usePaginationReducer({ id: 'faq-tests' });

  const labelHandler = label => {
    if (!label) {
      return;
    }

    if (label.length <= 40) {
      return label;
    }

    return label.substring(0, 50) + '...';
  };

  const query = useQuery([
    `faqs/regression-testing/${chosenTestId}`,
    { ...pagination.params, ...order.params }
  ]);

  return (
    <PanelSlider width="70%" header="Results" handleHide={handleHide}>
      <Flex>
        {query.isSuccess && query.data.data.length > 0 && (
          <Flex col border fat>
            <Table>
              <TableHead>
                <OrderableTableRow
                  rows={[
                    { label: 'Question', sort: 'query_string' },
                    { label: 'Result', sort: 'passed' },
                    { label: 'Expected FAQ', sort: 'expected' },
                    { label: 'Matched FAQ', sort: 'recieved' },
                    { label: 'Confidence', sort: 'confidence_score' },
                    { label: 'Explanation', sort: 'explanation' }
                  ]}
                  {...order.props}
                />
              </TableHead>
              <TableBody>
                {query.data.data.map(result => (
                  <TableRow key={result.id}>
                    <TableCell>
                      <Span title={result.query_string}>
                        {labelHandler(result.query_string)}
                      </Span>
                    </TableCell>
                    <TableCell>
                      <Span>{result.passed ? 'Matched' : 'Failure'}</Span>
                    </TableCell>
                    <TableCell>
                      {result.expected_faq ? (
                        <Link
                          as={Link}
                          to={`/faqs/${result.expected_faq.id}/questions`}
                          title={result.expected_faq.name}>
                          {labelHandler(result.expected_faq.name)}
                        </Link>
                      ) : (
                        'N/A'
                      )}
                    </TableCell>
                    <TableCell>
                      <Span>
                        {result.recieved_faq ? (
                          <Link
                            as={Link}
                            to={`/faqs/${result.recieved_faq.id}/questions`}
                            title={result.recieved_faq.name}>
                            {labelHandler(result.recieved_faq.name)}
                          </Link>
                        ) : (
                          'N/A'
                        )}
                      </Span>
                    </TableCell>
                    <TableCell>
                      <Span>
                        {result.confidence_score
                          ? result.confidence_score * 100 + '%'
                          : 'N/A'}
                      </Span>
                    </TableCell>
                    <TableCell>
                      <Span>
                        {labelHandler(result.explanation ?? 'None provided')}
                      </Span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {query.showPagination && (
              <Pagination pagination={query.data.meta} {...pagination.props} />
            )}
          </Flex>
        )}
        {query.isLoading && <Placeholder />}
        {query.isSuccess && query.data.data.length === 0 && (
          <Flex fat middle mt>
            <Span light>No results, please run your test case.</Span>
          </Flex>
        )}
      </Flex>
    </PanelSlider>
  );
};

ResultWindow.propTypes = {
  handleHide: PropTypes.func.isRequired,
  chosenTestId: PropTypes.number.isRequired
};

export default ResultWindow;
