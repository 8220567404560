import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Flex,
  Button,
  Placeholder,
  useNotification,
  useModal,
  Table,
  TableHead,
  TableRow,
  TableHeadCell,
  TableBody,
  TableCell,
  TableActions,
  Grid
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { useMutation, useQuery } from '@ubisend/pulse-hooks';

import {
  createCallToAction,
  updateCallToAction,
  deleteCallToAction
} from '../../api/index';
import CallToActionDetails from './CallToActionDetails';
import { useBotSettings } from '../../hooks';

const StyledGrid = styled(Grid)`
  ${tw`overflow-x-auto`}
`;

const CallToActions = () => {
  const [callToActions, setCallToActions] = useState([]);
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);

  const { settings } = useBotSettings();
  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();

  const { isSuccess } = useQuery(
    `converse/settings/${settings.id}/call-to-actions`,
    {
      onSuccess: ({ data }) => {
        setCallToActions(data);
      }
    }
  );

  const handleCreate = () => setCreate(true);

  const stopCreating = () => setCreate(false);

  const stopEditing = () => setEdit(false);

  const createCta = useMutation(createCallToAction, {
    onSuccess: ({ data }) => {
      setCallToActions(callToActions => callToActions.concat(data.data));
      showSuccess('Successfully created a new call-to-action');
      stopCreating();
    }
  });

  const updateCta = useMutation(updateCallToAction, {
    onSuccess: ({ data }) => {
      setCallToActions(callToActions => {
        return callToActions.map(callToAction => {
          if (callToAction.id === edit.id) {
            return data.data;
          }

          return callToAction;
        });
      });
      showSuccess('Successfully updated your call-to-action');
      stopEditing();
    }
  });

  const handleCreateSubmit = async params => {
    await createCta.mutate({
      ...params,
      setting_id: settings.id
    });
  };

  const handleUpdateSubmit = async params => {
    await updateCta.mutate({
      ...params,
      setting_id: settings.id,
      cta_id: edit.id
    });
  };

  const handleDeleteSubmit = ({ id }) => {
    showModal({
      header: 'Remove call-to-action',
      body: `Are you sure you want to delete this call-to-action?`,
      handleConfirm: async () => {
        try {
          await deleteCallToAction(id);

          setCallToActions(callToActions => {
            return callToActions.filter(callToAction => callToAction.id !== id);
          });
          showSuccess('Successfully deleted your call-to-action');
        } finally {
          hideModal();
        }
      }
    });
  };

  if (!isSuccess) {
    return <Placeholder items={1} subitems={3} />;
  }

  return (
    <Flex col ySpace>
      {create && (
        <CallToActionDetails
          handleSubmit={handleCreateSubmit}
          handleCancel={stopCreating}
        />
      )}
      {edit && (
        <CallToActionDetails
          handleSubmit={handleUpdateSubmit}
          handleCancel={stopEditing}
          initialDescription={edit.description}
          initialMessage={edit.message}
          initialConditionals={{
            conditionals: edit.conditionals,
            match: edit.match
          }}
        />
      )}
      {callToActions.length === 0 && (
        <Flex middle light>
          No call-to-actions have been set up yet
        </Flex>
      )}
      {(callToActions?.length > 0 || !callToActions) && (
        // TODO: Needs removing and proper behaviour added
        <Grid columns={1}>
          <StyledGrid columns={1}>
            <Table loading={!callToActions}>
              <TableHead>
                <TableRow>
                  <TableHeadCell>Description</TableHeadCell>
                  <TableHeadCell>Message</TableHeadCell>
                  <TableHeadCell />
                </TableRow>
              </TableHead>
              {callToActions && (
                <TableBody>
                  {callToActions.map((callToAction, key) => (
                    <TableRow key={key}>
                      <TableCell>{callToAction.description}</TableCell>
                      <TableCell>{callToAction.message}</TableCell>
                      <TableActions>
                        <PermissionFilter can="edit converse settings">
                          <Button
                            variant="secondary"
                            icon="pencilAlt"
                            onClick={() => setEdit(callToAction)}>
                            Edit
                          </Button>
                        </PermissionFilter>
                        <PermissionFilter can="delete converse settings">
                          <Button
                            data-testid="delete-cta"
                            variant="secondary"
                            icon="trash"
                            colour="danger"
                            onClick={() => handleDeleteSubmit(callToAction)}>
                            Delete
                          </Button>
                        </PermissionFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              )}
            </Table>
          </StyledGrid>
        </Grid>
      )}
      <PermissionFilter can="create converse settings">
        <Flex
          style={{
            justifyContent: callToActions.length > 0 ? 'flex-end' : 'center'
          }}>
          <Flex />
          <Button variant="secondary" onClick={handleCreate}>
            + Add new
          </Button>
        </Flex>
      </PermissionFilter>
    </Flex>
  );
};

export default CallToActions;
