import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Indicator as BaseIndicator } from '@ubisend/pulse-components';

const FatIndicator = styled(BaseIndicator)`
  ${tw`w-3 h-3 mr-2`}
`;

const Failed = () => <FatIndicator colour="danger" />;

const Success = () => <FatIndicator colour="positive" />;

const Pending = () => <FatIndicator colour="warning" />;

const levels = {
  Success: Success,
  Failed: Failed,
  'Partial Success': Failed,
  Pending: Pending
};

const StateLevel = ({ level }) => {
  if (!levels[level]) {
    return <Pending />;
  }

  const Indicator = levels[level];

  return <Indicator />;
};

StateLevel.propTypes = {
  level: PropTypes.string.isRequired
};

export default StateLevel;
