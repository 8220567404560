import React, { useState } from 'react';

import {
  Panel,
  Flex,
  InfoSection,
  OneQuarterLayout,
  Label,
  TextInput,
  Checkbox,
  Button
} from '@ubisend/pulse-components';
import { ImageSelect } from '@ubisend/pulse-files';

import { useBotSettings } from '../../hooks';

const ConverseFooterSettings = () => {
  const { settings, setSettings, focusBot } = useBotSettings();

  const [customFooter, setCustomFooter] = useState(false);

  const [useDefaultFooter, setUseDefaultFooter] = useState(
    !settings.styles.footerImageUrl
  );

  const handleDefaultFooterChange = () => {
    const newUse = !useDefaultFooter;
    focusBot();
    // If they switch to using the defaults, clear
    // the old bot url's.
    if (newUse) {
      setSettings(settings => ({
        ...settings,
        styles: {
          ...settings.styles,
          footerImageUrl: null
        }
      }));
    }

    setUseDefaultFooter(newUse);
  };

  const toggleBotFooter = () => {
    focusBot();
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        showPoweredByUbisend: !settings.styles.showPoweredByUbisend
      }
    }));
  };

  const toggleCustomFooterImage = () => setCustomFooter(open => !open);

  const handleFooterImage = ({ link }) => {
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        footerImageUrl: link
      }
    }));
    toggleCustomFooterImage();
  };

  const handleFooterUrlChange = e => {
    const value = e.target.value;
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        footerUrl: value
      }
    }));
  };

  return (
    <Flex>
      <OneQuarterLayout>
        <InfoSection
          title="Bot footer"
          info="Show or hide the bot footer, and a upload custom replacement."
        />
      </OneQuarterLayout>
      <Panel>
        <Flex col ySpace>
          <Checkbox
            checked={Boolean(settings.styles.showPoweredByUbisend)}
            onChange={toggleBotFooter}
            label="Show bot footer"
          />
          <Checkbox
            checked={!useDefaultFooter}
            onChange={handleDefaultFooterChange}
            label="Use custom footer"
            disabled={!settings.styles.showPoweredByUbisend}
          />
        </Flex>
        <Flex col mt mb>
          <Label>footer URL</Label>
          <TextInput
            aria-label="footer-url"
            placeholder="https://example.com"
            value={settings.styles.footerUrl}
            onChange={handleFooterUrlChange}
            disabled={useDefaultFooter}
          />
        </Flex>
        <Flex xSpace center mt>
          <Button
            selfTop
            disabled={useDefaultFooter}
            variant="secondary"
            onClick={toggleCustomFooterImage}>
            Choose footer image
          </Button>
        </Flex>
      </Panel>
      {customFooter && (
        <ImageSelect
          handleCancel={toggleCustomFooterImage}
          handleFileSelect={handleFooterImage}
        />
      )}
    </Flex>
  );
};

export default ConverseFooterSettings;
