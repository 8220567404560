import React from 'react';
import PropTypes from 'prop-types';

import { ErrorContainer } from '@ubisend/pulse-components';
import { BotProvider, DeactivatedQueryProvider } from '@ubisend/pulse-volt';

import { conversation } from '../replies';
import ErrorWidget from './ErrorWidget';

const ErrorView = ({ initialMessages = conversation, code, message }) => (
  <ErrorContainer code={code} message={message}>
    <DeactivatedQueryProvider>
      <BotProvider
        initialMessages={initialMessages}
        hasSeenBanner={true}
        initialOpen
        showClose={false}
        name={'Support bot'}
        showPoweredByUbisend={false}
        showLogo={false}>
        <ErrorWidget />
      </BotProvider>
    </DeactivatedQueryProvider>
  </ErrorContainer>
);

ErrorView.propTypes = {
  initialMessages: PropTypes.array,
  code: PropTypes.number.isRequired,
  message: PropTypes.string.isRequired
};

export default ErrorView;
