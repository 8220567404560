import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { SubscriptionProvider } from '@ubisend/pulse-subscribe';
import {
  Converse,
  formatSettings,
  createInitialMessages,
  ConverseDeliveryProvider,
  ThemeProvider,
  BotProvider,
  useQuery,
  AuthContext,
  LanguageProvider,
  SubscriberTypingProvider,
  NotificationProvider,
  TicketsProvider
} from '@ubisend/pulse-volt';

import { getConfig } from '../utils/index';

const { baseUrl, identifier, browserUrl, socketServer } = getConfig();

const getStart = data => {
  // Converse should be shown and initially opened.
  if (data.open) {
    return '/window/body/messages';
  }

  // Converse should be shown but initially closed.
  return '/';
};

const formatQueryData = data => {
  const { bot, styles } = formatSettings(data.settings);

  return {
    styles,
    bot: {
      ...bot,
      initialMessages: createInitialMessages(data.messages),
      initialShowCta: Boolean(data.call_to_action),
      ctaText: data.call_to_action
    },
    start: getStart(data),
    language: data.language,
    notifications: data.notifications,
    tickets: data.tickets
  };
};

const Bot = () => {
  const [token, setToken] = useState(null);

  const resultRef = useRef();

  const query = useQuery(['bot/converse/auth', { token }], {
    // Pulse params
    baseUrl,
    token,
    clientId: identifier,
    browserUrl,
    variables: [],
    channelDriverName: 'Converse',
    // Query params
    enabled: Boolean(
      !(token && resultRef.current) || resultRef.current !== token
    ),
    keepPreviousData: true,
    cacheTime: 0,
    onSuccess: data => {
      resultRef.current = data.token;

      setToken(data.token);
    }
  });

  // Don't show converse if the bot settings are loading or have errored. We
  // don't know what colour to theme the bot with.
  if (!query.isSuccess) {
    return null;
  }

  if (!query.data.show) {
    return null;
  }

  const {
    bot,
    styles,
    language,
    notifications,
    tickets,
    start
  } = formatQueryData(query.data);

  return (
    <ThemeProvider value={styles[0]}>
      <AuthContext.Provider
        value={{ token: query.data.token, setToken, query }}>
        <BotProvider {...bot}>
          <div
            style={{
              position: 'absolute',
              right: '1rem',
              bottom: '1rem',
              minWidth: '200px'
            }}>
            <ConverseDeliveryProvider
              client_id={identifier}
              browserUrl={browserUrl}
              variables={[]}
              base_url={baseUrl}>
              <LanguageProvider
                initialLanguage={language}
                namespaces={['bots', 'converse']}>
                <SubscriberTypingProvider>
                  <NotificationProvider initialNotifications={notifications}>
                    <TicketsProvider tickets={tickets}>
                      <Converse start={start} />
                    </TicketsProvider>
                  </NotificationProvider>
                </SubscriberTypingProvider>
              </LanguageProvider>
            </ConverseDeliveryProvider>
          </div>
        </BotProvider>
      </AuthContext.Provider>
    </ThemeProvider>
  );
};

const SupportBot = () => {
  const location = useLocation();

  if (location.pathname.slice(0, 5) !== '/docs') {
    return null;
  }

  return (
    <SubscriptionProvider url={socketServer}>
      <Bot />
    </SubscriptionProvider>
  );
};

export default SupportBot;
export { Bot };
