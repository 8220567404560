import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chroma from 'chroma-js';

import Icon from '@ubisend/pulse-icons';

import { colours } from '../styles';
import { pseudoStyles } from '../Input/TextInput';
import { Panel, InnerPanel } from '../Panel/index';
import Divider from '../Spacing/Divider';
import Placeholder from '../Loading/Placeholder';
import Flex from '../Layout/Flex';
import { MiniToggle } from '../Toggle';

const AccordionHeader = styled.button`
  ${tw`flex justify-between items-center cursor-pointer bg-white p-4 rounded border-0`}
  margin: -1rem;
  width: calc(100% + 2rem);
  border: 1px solid transparent;
  & * {
    ${tw`cursor-pointer`}
  }
  & div {
    ${tw`flex`}
  }
  ${pseudoStyles}
`;

const AccordionTitle = styled.div`
  &,
  & * {
    ${tw`block text-xs uppercase tracking-wide font-semibold font-poppins text-black`}
    ${colours}
  }

  ${({ secondary = false }) => secondary && `opacity: 0.5;`}
  ${({ mb = true }) => mb && tw`mb-2`}
${colours}
`;

const AccordionContent = styled.div`
  ${tw`p-4 pt-0`}
  margin: -1rem;
`;

const ButtonIcon = styled(Icon)`
  ${tw`cursor-pointer`}
`;

const StyledInnerPanel = styled(InnerPanel)`
  border: 1px solid
    ${props =>
      props.checked
        ? props.collapsed
          ? `border-grey-medium`
          : chroma(props.theme.primary).alpha(0.5)
        : 'border-grey-medium'};
`;

const StyledDivider = styled(Divider)`
  border-top-color: ${props =>
    props.checked
      ? props.collapsed
        ? `border-grey-medium`
        : chroma(props.theme.primary).alpha(0.5)
      : 'border-grey-medium'};
`;

const Accordion = ({
  children,
  header,
  open = false,
  actions,
  inner = true,
  disabled = false,
  loading = false,
  hasToggle,
  primary,
  checked,
  on,
  onChange,
  className,
  htmlFor,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState(!open);

  const handleClick = () => {
    setCollapsed(!collapsed);
    onChange?.();
  };

  useEffect(() => {
    if (hasToggle) {
      setCollapsed(open);
    }
  }, [on, hasToggle, open]);

  const Wrapper = inner ? StyledInnerPanel : Panel;

  const hasChildren = React.Children.count(children) > 0;

  return (
    <Wrapper className={className} checked={checked}>
      {loading ? (
        <Placeholder />
      ) : (
        <>
          <AccordionHeader
            {...props}
            id={header}
            disabled={disabled}
            collapsed={collapsed}
            hasToggle={hasToggle}
            onClick={handleClick}
            aria-expanded={!collapsed}
            type="button">
            <AccordionTitle htmlFor={htmlFor} mb={false}>
              {header}
            </AccordionTitle>

            <Flex grow center right>
              {actions}
              {!disabled &&
                (hasToggle ? (
                  <MiniToggle primary={primary} on={checked} />
                ) : (
                  <ButtonIcon
                    size="1.25rem"
                    height="1.25rem"
                    width="1.25rem"
                    type={collapsed ? `cheveronDown` : `cheveronUp`}
                  />
                ))}
            </Flex>
          </AccordionHeader>
          {(!collapsed && !hasToggle) || (checked && hasChildren) ? (
            <AccordionContent role="region" aria-labelledby={header}>
              <StyledDivider checked={checked} fullWidth />
              {children}
            </AccordionContent>
          ) : null}
        </>
      )}
    </Wrapper>
  );
};

Accordion.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  actions: PropTypes.element,
  open: PropTypes.bool,
  stretch: PropTypes.bool,
  inner: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  hasToggle: PropTypes.bool,
  primary: PropTypes.bool,
  checked: PropTypes.bool,
  on: PropTypes.bool,
  onChange: PropTypes.func,
  htmlFor: PropTypes.string
};

export default Accordion;
