import { css } from 'styled-components';
import chroma from 'chroma-js';

const grid = css`
  ${props => props.colSpan && `grid-column: span ${props.colSpan};`}
  ${props => props.rowSpan && `grid-row: span ${props.rowSpan};`}
`;

const size = css`
  ${props => props.tall && tw`h-full`}
  ${props => props.fat && tw`w-full`}
  ${props => props.autoHeight && tw`h-auto`}
  ${props => props.half && tw`w-1/2`}
  ${props => props.twoThirds && tw`w-2/3`}
  ${props => props.third && tw`w-1/3`}
  ${props => props.yScreen && tw`h-screen`}
  ${props => props.xScreen && tw`w-screen`}
  ${props => props.maxYScreen && tw`max-h-screen`}
  ${props => props.maxXScreen && tw`max-w-screen`}
  ${props => props.rounded && tw`rounded-lg`}
  ${props => props.height && `height: ${props.height};`}
  ${props => props.width && `width: ${props.width};`}
`;

const spacing = css`
  ${props => props.mxAuto && tw`mx-auto`}
  ${props => props.mtAuto && tw`mt-auto`}
  ${props => props.mrAuto && tw`mr-auto`}
  ${props => props.mbAuto && tw`mb-auto`}
  ${props => props.mlAuto && tw`ml-auto`}
  ${props => props.shiftUp && `margin-top: -1rem;`}
  ${props => props.shiftUpSm && `margin-top: -0.5rem;`}
  ${props => props.shiftDown && `margin-bottom: -1rem;`}
  ${props => props.shiftDownSm && `margin-bottom: -0.5rem;`}
  ${props => props.shiftRight && `margin-right: -1rem;`}
  ${props => props.shiftLeft && `margin-left: -1rem;`}
  ${props => props.mNone && tw`m-0`}
  ${props => props.mtNone && tw`mt-0`}
  ${props => props.mbNone && tw`mb-0`}
  ${props => props.mlNone && tw`ml-0`}
  ${props => props.mrNone && tw`mr-0`}
  ${props => props.mt && tw`mt-4`}
  ${props => props.mb && tw`mb-4`}
  ${props => props.ml && tw`ml-4`}
  ${props => props.mr && tw`mr-4`}
  ${props => props.mtSm && tw`mt-2`}
  ${props => props.mbSm && tw`mb-2`}
  ${props => props.mlSm && tw`ml-2`}
  ${props => props.mrSm && tw`mr-2`}
  ${props => props.pNone && tw`p-0`}
  ${props => props.ptNone && tw`pt-0`}
  ${props => props.pbNone && tw`pb-0`}
  ${props => props.plNone && tw`pl-0`}
  ${props => props.prNone && tw`pr-0`}
  ${props => props.pt && tw`pt-4`}
  ${props => props.pb && tw`pb-4`}
  ${props => props.pl && tw`pl-4`}
  ${props => props.pr && tw`pr-4`}
  ${props => props.ptSm && tw`pt-2`}
  ${props => props.pbSm && tw`pb-2`}
  ${props => props.plSm && tw`pl-2`}
  ${props => props.prSm && tw`pr-2`}
  ${props => props.pad && tw`p-4`}
  ${props => props.padSm && tw`p-2`}
  ${props => {
    if (props.gap) {
      return `
      row-gap: 1rem;
      `;
    }
  }}

  & > * {
    ${props => props.ySpace && tw`mb-4`}
    ${props => props.xSpace && tw`mr-4`}
    ${props => props.xSpaceSm && tw`mr-2`}
    ${props => props.ySpaceSm && tw`mb-2`}
    ${props => {
      if (props.growChildren) {
        return `
          flex-grow: 1;
          flex-basis: 0;
        `;
      }
    }}
  }
  & > *:last-child {
    ${props => (props.ySpace || props.ySpaceSm) && tw`mb-0`}
    ${props => (props.xSpace || props.xSpaceSm) && !props.wrap && tw`mr-0`}
  }
`;

const flex = css`
  ${props => props.flex && tw`flex`}
  ${props => props.inline && tw`inline-flex`}
  ${props => props.wrap && tw`flex-wrap`}
  ${props => props.col && !props.reverse && tw`flex-col`}
  ${props => props.row && !props.reverse && tw`flex-row`}
  ${props => props.reverse && tw`flex-row-reverse`}
  ${props => props.col && props.reverse && tw`flex-col-reverse`}
  ${props => props.center && tw`items-center`}
  ${props => props.top && tw`items-start`}
  ${props => props.bottom && tw`items-end`}
  ${props => props.selfCenter && tw`self-center`}
  ${props => props.selfTop && tw`self-start`}
  ${props => props.selfBottom && tw`self-end`}
  ${props => props.selfStretch && tw`self-stretch`}
  ${props => props.left && tw`justify-start`}
  ${props => props.middle && tw`justify-center`}
  ${props => props.between && tw`justify-between`}
  ${props => props.right && tw`justify-end`}
  ${props => props.grow && tw`flex-grow`}
  ${props => props.noGrow && tw`flex-no-grow`}
  ${props => props.shrink && tw`flex-shrink`}
  ${props => props.noShrink && tw`flex-no-shrink`}
  ${props => props.growShrink && tw`flex-1`}
`;

const position = css`
  ${props => props.fixed && tw`fixed`}
  ${props => props.sticky && tw`sticky`}
  ${props => props.relative && tw`relative`}
  ${props => props.absolute && tw`absolute`}
  ${props => props.yScroll && tw`overflow-y-auto`}
  ${props => props.xScroll && tw`overflow-x-auto`}
  ${props => props.yHidden && tw`overflow-y-hidden`}
  ${props => props.xHidden && tw`overflow-x-hidden`}
  ${props => props.xOverflow && tw`overflow-x-visible`}
  ${props => props.yOverflow && tw`overflow-y-visible`}
  ${props => props.pinRight && tw`pin-r`}
  ${props => props.pinLeft && tw`pin-l`}
  ${props => props.pinTop && tw`pin-t`}
  ${props => props.pinBottom && tw`pin-b`}
`;

const text = css`
  ${props => props.bold && tw`font-bold`}
  ${props => props.semiBold && tw`font-semibold`}
  ${props => props.light && tw`opacity-50`}
  ${props => props.uppercase && tw`uppercase`}
  ${props => props.tinyText && tw`text-xs`}
  ${props => props.largeText && tw`text-lg`}
  ${props => props.xlText && tw`text-xl`}
  ${props => props.baseText && tw`text-base`}
  ${props => props.smallText && tw`text-sm`}
  ${props => props.textRight && tw`text-right`}
  ${props => props.textLeft && tw`text-left`}
  ${props => props.textCenter && tw`text-center`}
  ${props => props.preWrap && tw`whitespace-pre-wrap`}
  ${props => props.noWrap && tw`whitespace-no-wrap`}
  ${props => props.poppins && tw`font-poppins`}
  ${props => props.sans && tw`font-sans`}
  ${props => props.breakWord && 'word-break: break-word;'}
  ${props => props.trackingWide && tw`tracking-wide`}
`;

const display = css`
  ${props => props.hidden && tw`hidden`}
  ${props => props.block && tw`block`}
`;

const active = css`
  ${({ active, theme }) => {
    if (active) {
      return `
        background-color: rgba(104, 75, 153, 0.125); color: ${theme.primary}
        border: solid 1px rgba(104, 75, 153, 0.5);
      `;
    }
  }}
`;

const colours = css`
  ${props =>
    props.background && `background: ${props.theme[props.background]};`}
  ${props => props.colour && `color: ${props.theme[props.colour]};`}
  ${props =>
    props.shadow &&
    `box-shadow: ${chroma(props.theme.black).alpha(0.25)} 0px 10px 10px -10px;`}
`;

const border = css`
  ${props => {
    return props.border && tw`border-grey-medium border-solid border`;
  }}
  ${props => {
    return (
      props.borderLeft && tw`border-grey-medium border-solid border-0 border-l`
    );
  }}
  ${props => {
    return (
      props.borderRight && tw`border-grey-medium border-solid border-0 border-r`
    );
  }}
  ${props => {
    return (
      props.borderTop && tw`border-grey-medium border-solid border-0 border-t`
    );
  }}
  ${props => {
    return (
      props.borderBottom &&
      tw`border-grey-medium border-solid border-0 border-b`
    );
  }}
`;

export {
  size,
  spacing,
  flex,
  position,
  text,
  display,
  active,
  grid,
  colours,
  border
};
