import React from 'react';

import {
  Explainer,
  Flex,
  OneHalfLayout,
  PageWrapper,
  Panel,
  Placeholder
} from '@ubisend/pulse-components';
import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import { PolicyAccept } from '@ubisend/pulse-channels';

import { acceptPolicy as acceptPolicyApi } from '../api/index';
import WhatsAppDataForm from '../Components/WhatsAppDataForm';

const WhatsAppLink = () => {
  const query = useQuery('whatsapp/settings');
  const queryClient = useQueryClient();

  const { mutate: acceptPolicy } = useMutation(acceptPolicyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('whatsapp/settings');
    }
  });

  const PolicyCheck = () => {
    if (!query.data.data.has_given_data_consent) {
      return (
        <PolicyAccept
          handlePolicyAccept={acceptPolicy}
          policyUrl="https://sopranodesign.com/privacy/"
        />
      );
    }

    return (
      <Panel divider header="Link your WhatsApp">
        <Flex col>
          <Explainer mb>
            Please fill out the fields below in line with Soprano Design
            WhatsApp Provider API.{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="/docs/2151317744/2580807850">
              Learn more
            </a>
            .
          </Explainer>
          <WhatsAppDataForm
            number={query.data.data.phone_number}
            token={query.data.data.api_id}
            prevBaseUri={query.data.data.base_uri}
            noSecret={query.data.data.has_not_api_key}
          />
        </Flex>
      </Panel>
    );
  };

  return (
    <PageWrapper center header="WhatsApp">
      <Flex col center>
        <OneHalfLayout>
          {query.isLoading && <Placeholder items={1} subitems={2} />}
          {query.isSuccess && <PolicyCheck />}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default WhatsAppLink;
