import React from 'react';

import { Tag } from '@ubisend/pulse-components';

const TriggerPostbackCancelContent = () => {
  return (
    <Tag background="warning" colour="warning">
      Beta
    </Tag>
  );
};

export default TriggerPostbackCancelContent;
