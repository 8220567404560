import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { pseudoStyles } from '@ubisend/pulse-components';

import styles from './styles';
import DisabledActionButton from '../DisabledActionButton';

const StyledButton = styled.a`
  ${styles}
  ${pseudoStyles}
`;

const MailTo = ({ response, handleButtonClick, hasHadReply, ...props }) => {
  const doesSendMessage = response.linkback ? true : false;
  if (hasHadReply && doesSendMessage && response.expires !== '') {
    return <DisabledActionButton response={response} {...props} />;
  }

  const handleClick = () => {
    if (response.linkback) {
      handleButtonClick(response.label, response.linkback);
    }

    return true;
  };

  return (
    <StyledButton
      {...props}
      colour="white"
      onClick={handleClick}
      href={`mailto:${response.action}`}
      target="_blank"
      rel="noopener noreferrer">
      {response.label}
    </StyledButton>
  );
};

MailTo.propTypes = {
  response: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    linkback: PropTypes.string,
    expires: PropTypes.bool
  }).isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  hasHadReply: PropTypes.bool
};

export default MailTo;
