import React from 'react';
import { Link } from 'react-router-dom';

import {
  PageWrapper,
  OneHalfLayout,
  Panel,
  Explainer,
  Flex,
  Label,
  TextInput,
  Button,
  Placeholder
} from '@ubisend/pulse-components';
import { useQuery, useQueryClient, useMutation } from '@ubisend/pulse-hooks';
import { PolicyAccept } from '@ubisend/pulse-channels';
import { useCopyToClipboard } from '@ubisend/pulse-component-hooks';

import { acceptPolicy as acceptPolicyApi } from '../api/index';

const VoipLink = () => {
  const query = useQuery('voip/settings');
  const queryClient = useQueryClient();

  const { mutate: acceptPolicy } = useMutation(acceptPolicyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('voip/settings');
    }
  });

  const { copy, copied } = useCopyToClipboard();

  const handleCopy = () => {
    copy(query.data.data.url);
  };

  const PolicyCheck = () => {
    if (!query.data.data.has_given_data_consent) {
      return (
        <PolicyAccept
          handlePolicyAccept={acceptPolicy}
          policyUrl="https://developers.facebook.com/devpolicy/"
        />
      );
    }

    return (
      <Panel divider header="Connect your chatbot to Voice over IP">
        <Explainer mb>
          Copy the link below into your VoiceAi platform. Follow our{' '}
          <Link to="/docs/2151317744/2223964202">documentation</Link> to set up
          your first VoiceAi chatbot.
        </Explainer>
        {query.isLoading && <Placeholder items={1} subitems={2} />}
        {query.isSuccess && (
          <Flex col>
            <Label htmlFor="api">API endpoint</Label>
            <Flex>
              <TextInput
                id="api"
                data-testid="voip-setting-input"
                disabled
                value={query.data.data.url}
              />
              <Button
                variant="primary"
                disabled={copied}
                icon="clipBoard"
                onClick={handleCopy}>
                {copied ? 'Copied' : 'Copy'}
              </Button>
            </Flex>
          </Flex>
        )}
      </Panel>
    );
  };

  return (
    <PageWrapper center header="Voice over IP">
      <Flex col center>
        <OneHalfLayout>
          {query.isLoading && <Placeholder items={1} subitems={2} />}
          {query.isSuccess && <PolicyCheck />}
        </OneHalfLayout>
      </Flex>
    </PageWrapper>
  );
};

export default VoipLink;
