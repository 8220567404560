import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chroma from 'chroma-js';

import { motion } from '@ubisend/framer-motion';

import { Button } from '../Button/index';

const ModalContainer = styled(motion.div)`
  ${props =>
    !props.static &&
    `
      @keyframes fadeIn {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      animation: 0.1s ease-out 0s 1 fadeIn;
  `}
  ${tw`fixed pin-t pin-l w-screen h-screen flex flex-col items-center justify-center `};
  z-index: 100;
  background: ${({ theme }) => chroma(theme.primary).darken(3.25).alpha(0.66)};
`;

const ModalContent = styled(motion.div)`
  ${props =>
    !props.static &&
    `
      @keyframes fadeInUp {
        0% {
          opacity: 0;
          transform: scale(0.9);
        }
        100% {
          opacity: 1;
          transform: scale(1);
        }
      }
      animation: 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 fadeInUp;
      animation-fill-mode: both;
    `}

  ${tw`bg-white rounded shadow w-4/5 lg:w-2/5 max-w-md border border-solid border-grey-medium`}
`;

const ModalHeader = styled(motion.div)`
  ${tw`flex justify-between items-center rounded-tr rounded-tl text-xl font-bold px-6 py-4 font-poppins border-grey-medium border-0 border-b border-solid`};
`;

const ModalBody = styled(motion.div)`
  ${tw`px-6 py-4 font-light leading-normal`}
`;

const ModalFooter = styled(motion.div)`
  ${tw`flex items-center justify-end px-6 py-4`}
`;

const ModalCancel = styled(motion.div)`
  ${tw`text-xs tracking-wide font-medium mr-6 text-granite cursor-pointer font-poppins uppercase`}
`;

const Modal = ({
  header,
  body,
  cancelText,
  handleCancel,
  handleConfirm,
  confirmText
}) => {
  const [loading, setLoading] = useState(false);

  const processConfirm = async () => {
    setLoading(true);
    await handleConfirm();
    setLoading(false);
  };

  return (
    <ModalContainer>
      <ModalContent>
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <ModalCancel onClick={handleCancel}>{cancelText}</ModalCancel>
          <Button variant="primary" loading={loading} onClick={processConfirm}>
            {confirmText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  );
};

Modal.propTypes = {
  header: PropTypes.element.isRequired,
  body: PropTypes.element.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  cancelText: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired
};

ModalContent.displayName = 'ModalContent';
ModalHeader.displayName = 'ModalHeader';
ModalBody.displayName = 'ModalBody';
ModalFooter.displayName = 'ModalFooter';
ModalCancel.displayName = 'ModalCancel';

export {
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCancel,
  Modal
};
