import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  ${tw`flex`}
`;

const TabularContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default TabularContainer;
