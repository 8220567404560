import React, { useState } from 'react';

import {
  Flex,
  Button,
  Placeholder,
  Span,
  Accordion,
  useNotification,
  useModal,
  Explainer,
  Divider
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';

import {
  createInitialOpen as createInitialOpenApi,
  updateInitialOpen as updateInitialOpenApi,
  deleteInitialOpen as deleteInitialOpenApi
} from '../../api/index';
import ConditionalModal from './ConditionalModal';
import { useBotSettings } from '../../hooks';

const InitialOpenSettings = () => {
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);

  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();
  const { settings } = useBotSettings();

  const handleCreate = () => setCreate(true);

  const stopCreating = () => setCreate(false);

  const stopEditing = () => setEdit(false);

  const queryClient = useQueryClient();
  const { data, isLoading, isSuccess } = useQuery(
    `converse/settings/${settings.id}/initial-open`
  );
  const { mutate: createInitialOpen } = useMutation(createInitialOpenApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        `converse/settings/${settings.id}/initial-open`
      );
      showSuccess('Successfully created a new initial open setting.');
      stopCreating();
    }
  });
  const { mutate: updateInitialOpen } = useMutation(updateInitialOpenApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        `converse/settings/${settings.id}/initial-open`
      );
      showSuccess('Successfully updated your initial open settings.');
      stopEditing();
    }
  });
  const { mutate: deleteInitialOpen } = useMutation(deleteInitialOpenApi, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        `converse/settings/${settings.id}/initial-open`
      );
      showSuccess('Successfully deleted your initial open setting.');
      stopEditing();
    }
  });

  const handleCreateSubmit = params => {
    createInitialOpen({ setting_id: settings.id, ...params });
  };

  const handleUpdateSubmit = params => {
    updateInitialOpen({ id: edit.id, setting_id: settings.id, ...params });
  };

  const handleDeleteSubmit = ({ id }) => {
    showModal({
      header: 'Remove open rule',
      body: `Are you sure you want to delete this open rule?`,
      handleConfirm: async () => {
        try {
          await deleteInitialOpen(id);
        } finally {
          hideModal();
        }
      }
    });
  };

  return (
    <Accordion inner header="Chat window open rules">
      {isLoading && <Placeholder items={2} />}
      {isSuccess && (
        <Flex col ySpace>
          {create && (
            <ConditionalModal
              header="Configure whether your chatbot is open when the page is first loaded"
              subHeading="When to automatically open the widget after the page loads"
              handleSubmit={handleCreateSubmit}
              handleCancel={stopCreating}
            />
          )}
          {edit && (
            <ConditionalModal
              header="Configure whether your chatbot is open when the page is first loaded"
              subHeading="When to automatically open the widget after the page loads"
              handleSubmit={handleUpdateSubmit}
              handleCancel={stopEditing}
              initialName={edit.name}
              initialConditionals={{
                conditionals: edit.conditionals,
                match: edit.match
              }}
            />
          )}
          <Explainer>
            Control whether your chatbot window is open when a user first visits
            a page.
          </Explainer>
          {data.data.length === 0 && (
            <Span light>No smart rules have been setup yet</Span>
          )}
          {data.data.map((initialOpen, key) => (
            <Flex between center key={key}>
              <Span>{initialOpen.name}</Span>
              <Flex xSpace>
                <PermissionFilter can="edit converse settings">
                  <Button
                    onClick={() => setEdit(initialOpen)}
                    variant="secondary">
                    Edit
                  </Button>
                </PermissionFilter>
                <PermissionFilter can="delete converse settings">
                  <Button
                    variant="secondary"
                    colour="danger"
                    icon="trash"
                    onClick={() => handleDeleteSubmit(initialOpen)}>
                    Delete
                  </Button>
                </PermissionFilter>
              </Flex>
            </Flex>
          ))}
          <PermissionFilter can="create converse settings">
            <Divider mtNone />
            <Flex right>
              <Button onClick={handleCreate} variant="secondary">
                + Add new
              </Button>
            </Flex>
          </PermissionFilter>
        </Flex>
      )}
    </Accordion>
  );
};

export default InitialOpenSettings;
