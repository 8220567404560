import React from 'react';

import { AnimatePresence } from '@ubisend/framer-motion';
import { Flex } from '@ubisend/pulse-components';

import {
  Composer,
  WidgetFooter,
  BurgerMenu,
  Messages as MessagesComponent
} from '../../Converse/Components/index';
import { RecordSubscriberTyping } from '../../../Components/index';
import { Body } from '../Components/index';
import { ComposerProvider } from '../../../Providers/index';
import { useDelivery, useBot, useJumpToBottom } from '../../../hooks/index';

const Messages = () => {
  const { sendMessage } = useDelivery();
  const { showPoweredByUbisend, showBurgerMenu } = useBot();
  const { showJump, handleScroll } = useJumpToBottom();

  return (
    <>
      <Body onScroll={handleScroll}>
        <MessagesComponent showJump={showJump} />
      </Body>
      <AnimatePresence>
        {showBurgerMenu && <BurgerMenu handleButtonClick={sendMessage} />}
      </AnimatePresence>
      <ComposerProvider>
        <RecordSubscriberTyping />
        <Composer channel="embed" />
      </ComposerProvider>
      <Flex between background="white" pbSm pl pr>
        <Flex id="chatbot-footer" />
        {showPoweredByUbisend && <WidgetFooter />}
      </Flex>
    </>
  );
};

export default Messages;
