import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  Panel,
  Flex,
  InfoSection,
  OneQuarterLayout,
  Label,
  TextInput,
  Radio,
  Button,
  Divider,
  Accordion,
  Paragraph
} from '@ubisend/pulse-components';
import { ImageSelect } from '@ubisend/pulse-files';

import { useBotSettings } from '../../hooks';

const ConverseFooterSettings = props => {
  const { settings, setSettings, focusBot } = useBotSettings();

  const [customFooter, setCustomFooter] = useState(false);

  const [useDefaultFooter, setUseDefaultFooter] = useState(
    !settings.styles.footerImageUrl
  );

  const handleDefaultFooterChange = () => {
    const newUse = !useDefaultFooter;
    focusBot();
    // If they switch to using the defaults, clear
    // the old bot url's.
    if (newUse) {
      setSettings(settings => ({
        ...settings,
        styles: {
          ...settings.styles,
          footerImageUrl: null
        }
      }));
    }

    setUseDefaultFooter(newUse);
  };

  const toggleBotFooter = () => {
    focusBot();
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        showPoweredByUbisend: !settings.styles.showPoweredByUbisend
      }
    }));
  };

  const toggleCustomFooterImage = () => setCustomFooter(open => !open);

  const handleFooterImage = ({ link }) => {
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        footerImageUrl: link
      }
    }));
    toggleCustomFooterImage();
  };

  const handleFooterUrlChange = e => {
    const value = e.target.value;
    setSettings(settings => ({
      ...settings,
      styles: {
        ...settings.styles,
        footerUrl: value
      }
    }));
  };

  return (
    <Flex>
      {props.titlePosition === 'left' && (
        <OneQuarterLayout>
          <InfoSection
            title="Bot footer"
            info="Show or hide the bot footer, and a upload custom replacement."
          />
        </OneQuarterLayout>
      )}
      <Panel>
        {props.titlePosition === 'top' && (
          <>
            <InfoSection
              mtNone
              title="Bot footer"
              info="Show or hide the bot footer, and a upload custom replacement."
            />
            <Divider />
          </>
        )}
        <Accordion
          header="Show bot footer"
          primary
          hasToggle
          checked={settings.styles.showPoweredByUbisend}
          onChange={toggleBotFooter}>
          <Flex col ySpace role="radiogroup">
            <Radio
              checked={useDefaultFooter}
              value="default"
              onChange={() => handleDefaultFooterChange(true)}
              label="Use default footer"
              name="footer"
            />
            <Radio
              checked={!useDefaultFooter}
              value="custom"
              onChange={() => handleDefaultFooterChange(false)}
              label="Use custom footer"
              name="footer"
            />
          </Flex>
          {!useDefaultFooter && (
            <>
              <Flex col mt mb>
                <Label>footer URL</Label>
                <TextInput
                  aria-label="footer-url"
                  placeholder="https://example.com"
                  value={settings.styles.footerUrl}
                  onChange={handleFooterUrlChange}
                  disabled={useDefaultFooter}
                />
              </Flex>

              <Flex block col>
                <Flex>
                  <Flex border rounded pad col center mr>
                    <Label>Footer image</Label>
                    <Paragraph mb smallText colour="grey">
                      {settings.styles.footerImageUrl === null
                        ? 'No image selected'
                        : 'Current image:'}
                    </Paragraph>
                    {settings.styles.footerImageUrl && (
                      <img
                        alt="Bot footer logo"
                        style={{ width: '2rem', marginBottom: '1rem' }}
                        src={settings.styles.footerImageUrl}
                      />
                    )}
                    <Button
                      mtAuto
                      disabled={useDefaultFooter}
                      variant="secondary"
                      onClick={toggleCustomFooterImage}>
                      Choose image
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </>
          )}
        </Accordion>
      </Panel>
      {customFooter && (
        <ImageSelect
          handleCancel={toggleCustomFooterImage}
          handleFileSelect={handleFooterImage}
        />
      )}
    </Flex>
  );
};

ConverseFooterSettings.propTypes = {
  titlePosition: PropTypes.string
};

ConverseFooterSettings.defaultProps = {
  titlePosition: 'left'
};

export default ConverseFooterSettings;
