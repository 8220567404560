import React from 'react';
import PropTypes from 'prop-types';

import {
  Tab,
  TabularContainer
} from '@ubisend/pulse-components/src/Components/Tabs';
import { Button } from '@ubisend/pulse-components';

import { useBotSettings } from '../../hooks';

const AllTabs = ({ showWidgetCreator, setShowWidgetCreator }) => {
  const {
    allSettings,
    handleCurrentSettingChange,
    currentSetting
  } = useBotSettings();

  const titleHandler = title => {
    if (!title) {
      return;
    }

    if (title.length <= 40) {
      return title;
    }
    return title.substring(0, 40) + '...';
  };

  return (
    <TabularContainer>
      {allSettings?.length > 0 && (
        <>
          {allSettings.map(setting => (
            <Tab
              key={setting.id}
              aria-label={`dashboard-${setting.id}`}
              title={titleHandler(setting.dashboard_name)}
              onClick={() => handleCurrentSettingChange(setting.id)}
              isSelected={currentSetting === setting.id}
            />
          ))}
          <Button
            icon="plus"
            data-testid={'add-new-bot-setting'}
            onClick={() => setShowWidgetCreator(!showWidgetCreator)}></Button>
        </>
      )}
    </TabularContainer>
  );
};

AllTabs.propTypes = {
  showWidgetCreator: PropTypes.bool,
  setShowWidgetCreator: PropTypes.func.isRequired
};

export default AllTabs;
