import React from 'react';
import PropTypes from 'prop-types';

import {
  TextInput,
  InnerPanel,
  Flex,
  Label,
  Tooltip,
  Accordion,
  Button,
  Checkbox,
  Span
} from '@ubisend/pulse-components';

import AllLanguagesSelect from './AllLanguagesSelect';
import CountrySelect from './CountrySelect';
import { useClientReducer } from '../reducers/index';

const ClientDetails = ({ initialClient, onSubmit, loading }) => {
  const client = useClientReducer(initialClient);

  const handleSubmit = event => {
    event.preventDefault();

    if (!client.valid || loading) {
      return;
    }

    onSubmit(client.form);
  };

  const handleNameChange = event => {
    const name = event.target.value;

    client.dispatch({ type: client.TYPES.UPDATE_NAME, name });
  };

  const handleEmailChange = event => {
    const email = event.target.value;

    client.dispatch({ type: client.TYPES.UPDATE_EMAIL, email });
  };

  const handleLanguageIdChange = option => {
    client.dispatch({
      type: client.TYPES.UPDATE_LANGUAGE_ID,
      language_id: option.value
    });
  };

  const handlePhoneChange = event => {
    const phone = event.target.value;

    client.dispatch({ type: client.TYPES.UPDATE_PHONE, phone });
  };

  const handleAddressOneChange = event => {
    const address = event.target.value;

    client.dispatch({
      type: client.TYPES.UPDATE_ADDRESS_1,
      address_1: address
    });
  };

  const handleAddressTwoChange = event => {
    const address = event.target.value;

    client.dispatch({
      type: client.TYPES.UPDATE_ADDRESS_2,
      address_2: address
    });
  };

  const handleCityChange = event => {
    const city = event.target.value;

    client.dispatch({
      type: client.TYPES.UPDATE_CITY,
      city
    });
  };

  const handleRegionChange = event => {
    const region = event.target.value;

    client.dispatch({
      type: client.TYPES.UPDATE_REGION,
      region
    });
  };

  const handlePostcodeChange = event => {
    const postcode = event.target.value;

    client.dispatch({
      type: client.TYPES.UPDATE_POSTCODE,
      postcode
    });
  };

  const handleCountryChange = option => {
    client.dispatch({
      type: client.TYPES.UPDATE_COUNTRY,
      country: option.value
    });
  };

  const handleAuthorisationFeatureChange = event => {
    if (!event.target.checked) {
      client.dispatch({
        type: client.TYPES.REMOVE_FEATURE,
        feature: 'authorisation'
      });
    } else {
      client.dispatch({
        type: client.TYPES.ADD_FEATURE,
        feature: 'authorisation'
      });
    }
  };

  const handleWhitelabelFooterFeatureChange = event => {
    if (!event.target.checked) {
      client.dispatch({
        type: client.TYPES.REMOVE_FEATURE,
        feature: 'whitelabel footer'
      });
    } else {
      client.dispatch({
        type: client.TYPES.ADD_FEATURE,
        feature: 'whitelabel footer'
      });
    }
  };

  const handleFaqRegressionTestFeatureChange = event => {
    if (!event.target.checked) {
      client.dispatch({
        type: client.TYPES.REMOVE_FEATURE,
        feature: 'faq regression testing'
      });
    } else {
      client.dispatch({
        type: client.TYPES.ADD_FEATURE,
        feature: 'faq regression testing'
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <InnerPanel mb>
        <Flex col>
          <Flex col fat ySpace>
            <Flex col>
              <Label htmlFor="name">Name</Label>
              <TextInput
                name="name"
                onChange={handleNameChange}
                value={client.client.name}
                id="name"
                placeholder="Joe Bloggs"
              />
            </Flex>
            <Flex col>
              <Label htmlFor="email">Email</Label>
              <TextInput
                name="email"
                onChange={handleEmailChange}
                value={client.client.email}
                id="email"
                placeholder="joe@bloggs.com"
              />
            </Flex>
            <Flex mb col>
              <Flex mbSm center>
                <Label mb={false} htmlFor="language">
                  Default Language
                </Label>
                <Tooltip
                  tooltip={
                    <Tooltip.Content>
                      Responses and FAQs can be written in multiple languages.
                      The default will be used when building these, as well as
                      the language for new subscribers.
                    </Tooltip.Content>
                  }>
                  <Tooltip.Icon>?</Tooltip.Icon>
                </Tooltip>
              </Flex>
              <AllLanguagesSelect
                name="language"
                id="language"
                value={client.client.language_id}
                onChange={handleLanguageIdChange}
              />
            </Flex>
          </Flex>
        </Flex>
      </InnerPanel>
      <Flex mb col>
        <Accordion header="Additional Details">
          <Flex col ySpace fat>
            <Flex col>
              <Label htmlFor="phone">Phone</Label>
              <TextInput
                name="phone"
                onChange={handlePhoneChange}
                value={client.client.phone}
                id="phone"
                placeholder="012345678900"
              />
            </Flex>
            <Flex col>
              <Label htmlFor="address_1">Address line 1</Label>
              <TextInput
                name="address_1"
                onChange={handleAddressOneChange}
                value={client.client.address_1}
                id="address_1"
                placeholder="1, A Street"
              />
            </Flex>
            <Flex col>
              <Label htmlFor="address_2">Address line 2</Label>
              <TextInput
                name="address_2"
                onChange={handleAddressTwoChange}
                value={client.client.address_2}
                id="address_2"
                placeholder="A Place"
              />
            </Flex>
            <Flex col>
              <Label htmlFor="city">City</Label>
              <TextInput
                name="city"
                onChange={handleCityChange}
                value={client.client.city}
                id="city"
                placeholder="London"
              />
            </Flex>
            <Flex col>
              <Label htmlFor="region">Region</Label>
              <TextInput
                name="region"
                onChange={handleRegionChange}
                value={client.client.region}
                id="region"
                placeholder="London"
              />
            </Flex>
            <Flex col>
              <Label htmlFor="postcode">Postcode</Label>
              <TextInput
                name="postcode"
                onChange={handlePostcodeChange}
                value={client.client.postcode}
                id="postcode"
                placeholder="SW1A 2AA"
              />
            </Flex>
            <Flex col>
              <Label htmlFor="country">Country</Label>
              <CountrySelect
                name="country"
                id="country"
                value={client.client.country}
                onChange={handleCountryChange}
              />
            </Flex>
          </Flex>
        </Accordion>
      </Flex>
      <Accordion header="Opt in features">
        <Flex xSpaceSm center between fat>
          <Checkbox
            label="Authorisation"
            checked={client.hasFeature('authorisation')}
            onChange={handleAuthorisationFeatureChange}
          />
          <Span tinyText>
            Authorise your chatbot users to restrict access to private
            conversations.
          </Span>
        </Flex>
        <Flex xSpaceSm center between fat mt>
          <Checkbox
            label="White labelled bot footer"
            checked={client.hasFeature('whitelabel footer')}
            onChange={handleWhitelabelFooterFeatureChange}
          />
          <Span tinyText>
            Allow this account to customise their chatbot footer.
          </Span>
        </Flex>
        <Flex xSpaceSm center between fat mt>
          <Checkbox
            label="Faq Regression Testing"
            checked={client.hasFeature('faq regression testing')}
            onChange={handleFaqRegressionTestFeatureChange}
          />
          <Span tinyText>
            Allow this account to access FAQ regression testing.
          </Span>
        </Flex>
      </Accordion>
      <Flex right mt>
        <Button
          type="submit"
          disabled={!client.valid}
          variant="primary"
          loading={loading}>
          Save
        </Button>
      </Flex>
    </form>
  );
};

ClientDetails.propTypes = {
  initialClient: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    language_id: PropTypes.number.isRequired,
    phone: PropTypes.string,
    address_1: PropTypes.string,
    address_2: PropTypes.string,
    city: PropTypes.string,
    region: PropTypes.string,
    postcode: PropTypes.string,
    country: PropTypes.string
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ClientDetails;
