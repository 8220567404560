import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  Flex,
  Tooltip,
  Panel,
  Button,
  TextInput,
  OneQuarterLayout,
  InfoSection,
  Label,
  Divider,
  TabButton,
  Accordion,
  Paragraph
} from '@ubisend/pulse-components';
import { ImageSelect } from '@ubisend/pulse-files';
import { useLanguages, LanguagesConsumer } from '@ubisend/pulse-hooks';

import { useBotSettings } from '../../hooks/index';

const ConverseGeneralSettings = props => {
  const [selectImage, setSelectImage] = useState();

  const {
    settings,
    setSettings,
    language,
    setLanguage,
    name,
    focusBot,
    handleNewImage
  } = useBotSettings();
  const { languages } = useLanguages();

  const showSelectImage = () => setSelectImage(true);

  const hideSelectImage = () => setSelectImage(false);

  const handleResetBotOnClose = () => {
    setSettings(settings => ({
      ...settings,
      reset_on_close: !settings.reset_on_close
    }));
  };

  const handleShowNameToggle = () => {
    setSettings(settings => ({
      ...settings,
      name_enabled: !settings.name_enabled
    }));
  };

  const handleNameChange = event => {
    const newName = event.target.value;

    setSettings(settings => ({
      ...settings,
      names: settings.names.map(name => {
        if (name.language_id === language.id) {
          return {
            language_id: language.id,
            content: { name: newName }
          };
        }

        return name;
      })
    }));
  };

  const handleShowLogoToggle = () => {
    setSettings(settings => ({
      ...settings,
      logo_enabled: !settings.logo_enabled
    }));
  };

  const handleShowTimestampToggle = () => {
    setSettings(settings => ({
      ...settings,
      timestamp_enabled: !settings.timestamp_enabled
    }));
  };

  const handleImageChange = file => {
    handleNewImage(file);
    hideSelectImage();
  };

  return (
    <Flex>
      {props.titlePosition === 'left' && (
        <OneQuarterLayout>
          <InfoSection
            title="Main Settings"
            info="Adjust the copy displayed inside your chatbot, and add a logo."
          />
        </OneQuarterLayout>
      )}
      <Panel>
        {props.titlePosition === 'top' && (
          <>
            <InfoSection
              mtNone
              title="Main Settings"
              info="Adjust the copy displayed inside your chatbot, and add a logo."
            />
            <Divider />
          </>
        )}
        <Flex col ySpace>
          <LanguagesConsumer>
            <Flex>
              <AnimateSharedLayout>
                {languages.map(languageTab => (
                  <TabButton
                    onClick={() => setLanguage(languageTab)}
                    active={languageTab.id === language.id}
                    key={languageTab.id}>
                    {languageTab.name}
                  </TabButton>
                ))}
              </AnimateSharedLayout>
            </Flex>
            <Divider fullWidth mtNone />
          </LanguagesConsumer>
          {selectImage && (
            <ImageSelect
              handleCancel={hideSelectImage}
              handleFileSelect={handleImageChange}
            />
          )}
          <Flex col ySpaceSm grow>
            <Accordion
              header="Show chatbot name"
              primary
              hasToggle
              checked={settings.name_enabled}
              onChange={handleShowNameToggle}>
              <Label>Chatbot name</Label>
              <TextInput
                disabled={!settings.name_enabled}
                aria-label="bot-name"
                value={name}
                onChange={handleNameChange}
                onClick={focusBot}
                maxLength="255"
              />
            </Accordion>
          </Flex>
          <Flex col ySpaceSm>
            <Accordion
              header="Show chatbot logo"
              primary
              hasToggle
              checked={settings.logo_enabled}
              onChange={handleShowLogoToggle}>
              <Flex block col>
                <Flex>
                  <Flex border rounded pad col center mr>
                    <Label>Logo image</Label>
                    <Paragraph mb smallText colour="grey">
                      {settings.logo === null
                        ? 'No image selected'
                        : 'Current image:'}
                    </Paragraph>
                    {settings.logo && (
                      <img
                        alt="Bot logo"
                        style={{ width: '2rem', marginBottom: '1rem' }}
                        src={settings.logo}
                      />
                    )}
                    <Button
                      mtAuto
                      disabled={!settings.logo_enabled}
                      variant="secondary"
                      onClick={showSelectImage}>
                      Change Logo
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            </Accordion>
          </Flex>
          <Flex mb>
            <Accordion
              primary
              hasToggle
              checked={settings.timestamp_enabled}
              onChange={handleShowTimestampToggle}
              header="Toggle show timestamp"
            />
          </Flex>
          <Flex center>
            <Accordion
              primary
              hasToggle
              checked={settings.reset_on_close}
              onChange={handleResetBotOnClose}
              header="Reset chatbot on close"
              actions={
                <Flex className="tooltip" style={{ marginRight: 'auto' }}>
                  <Tooltip
                    tooltip={
                      <Tooltip.Content>
                        Reset the chatbot conversation back to the start when
                        the widget is closed
                      </Tooltip.Content>
                    }>
                    <Tooltip.Icon>?</Tooltip.Icon>
                  </Tooltip>
                </Flex>
              }
            />
          </Flex>
        </Flex>
      </Panel>
    </Flex>
  );
};

ConverseGeneralSettings.propTypes = {
  titlePosition: PropTypes.string
};

ConverseGeneralSettings.defaultProps = {
  titlePosition: 'left'
};

export default ConverseGeneralSettings;
