import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { pseudoStyles } from '@ubisend/pulse-components';

import styles from './styles';
import DisabledActionButton from '../DisabledActionButton';

const StyledButton = styled.div`
  ${styles}
  ${pseudoStyles}
`;

const ContentButton = ({
  response,
  handleButtonClick,
  hasHadReply,
  ...props
}) => {
  const history = useHistory();

  const doesSendMessage = response.linkback ? true : false;

  if (hasHadReply && doesSendMessage && response.expires !== '') {
    return <DisabledActionButton response={response} {...props} />;
  }

  const handleClick = () => {
    history.push(`/window/section/${response.action}`);

    if (response.linkback) {
      handleButtonClick(response.label, response.linkback);
    }

    return true;
  };

  return (
    <StyledButton {...props} colour="white" onClick={handleClick}>
      {response.label}
    </StyledButton>
  );
};

ContentButton.propTypes = {
  response: PropTypes.shape({
    label: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    linkback: PropTypes.string,
    expires: PropTypes.bool
  }).isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  hasHadReply: PropTypes.bool
};

export default ContentButton;
