import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Label, Flex, Paragraph, Divider } from '@ubisend/pulse-components';

import { ButtonContext } from '../../Contexts/index';
import AddButton from './AddButton';
import Button from './Button';
import types from './Types/index';

const MAX_RESPONSES = 10;
const MIN_RESPONSES = 0;

const generateId = () => {
  return Math.random();
};

const ButtonComposer = ({
  value = [],
  onChange,
  only,
  max = MAX_RESPONSES,
  min = MIN_RESPONSES
}) => {
  const internalUpdate = useRef();
  const [buttons, setButtons] = useState(
    value.map(value => ({ id: generateId(), ...value }))
  );

  /**
   * If the outside value of the buttons change, and it isn't from us updating
   * it via updateButtons(), reset the state.
   */
  useEffect(() => {
    if (!internalUpdate.current) {
      setButtons(value.map(value => ({ id: generateId(), ...value })));
    } else {
      internalUpdate.current = false;
    }
  }, [value]);

  const updateButtons = newButtons => {
    internalUpdate.current = true;

    onChange(newButtons.map(({ id, ...button }) => button));
    setButtons(newButtons);
  };

  const handleButton = newButton => {
    updateButtons(buttons.concat([{ id: generateId(), ...newButton }]));
  };

  const handleButtonChange = (index, changedKey, newValue) => {
    updateButtons(
      buttons.map((button, key) => {
        if (key === index) {
          return { ...button, [changedKey]: newValue };
        }
        return button;
      })
    );
  };

  const handleButtonDelete = index => {
    updateButtons(buttons.filter((_, key) => key !== index));
  };

  const handleButtonMove = (fromIndex, toIndex) => {
    const mutableButtons = [...buttons];
    mutableButtons.splice(toIndex, 0, mutableButtons.splice(fromIndex, 1)[0]);
    updateButtons(mutableButtons);
  };

  const context = {
    value: buttons,
    types: only ? types.all.filter(type => only.includes(type.id)) : types.all,
    onChange,
    handleButton,
    handleButtonChange,
    handleButtonDelete,
    handleButtonMove,
    max,
    min
  };

  return (
    <ButtonContext.Provider value={context}>
      <Label>Buttons (Up to {max})</Label>
      <Flex col ySpace>
        {buttons.length === 0 && (
          <Paragraph secondary>No buttons have been set up yet</Paragraph>
        )}
        {buttons.length > 0 && (
          <Flex col ySpace>
            {buttons.map((button, key) => (
              <Button key={button.id} button={button} index={key} />
            ))}
          </Flex>
        )}
        {buttons.length < max && (
          <>
            <Divider />
            <AddButton />
          </>
        )}
      </Flex>
    </ButtonContext.Provider>
  );
};

ButtonComposer.propTypes = {
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  only: PropTypes.array,
  max: PropTypes.number,
  min: PropTypes.number
};

export default ButtonComposer;
