import React from 'react';
import PropTypes from 'prop-types';
import Flatpickr from 'react-flatpickr';
import confirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate';
import styled, { createGlobalStyle } from 'styled-components';

import 'flatpickr/dist/themes/dark.css';
import { inputStyles } from './TextInput';

const OveriddenFlatpickrStyles = createGlobalStyle`
  .flatpickr-current-month {
    padding: 2px 0 0 0 !important;
  }
  .flatpickr-calendar,
  .flatpickr-month,
  .flatpickr-current-month,
  .flatpickr-monthDropdown-months,
  span.flatpickr-weekday {
    background: #1a1a1a !important;
  }
  .flatpickr-day, .flatpickr-am-pm {
    font-family: "Poppins", sans-serif;
  }
  .flatpickr-day.selected {
    background: #684b99 !important;
  }
  .flatpickr-confirm {
    border-top: solid 1px #20222c;
    font-family: "Poppins", sans-serif;
    color: #fff;
    margin: 0.5rem;
    padding: 0.5rem;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #fff;
    svg {
      path {
        fill: #fff;
      }
    }
    &:hover, &:focus {
      background-color: #646c8c;
    }
    &:focus-visible {
      outline: #fff auto 3px;
  }
  }
`;

/**
 * Wrapper for flatpickr to show date time.
 *
 * Flatpickr: https://github.com/flatpickr/flatpickr
 * Flatpickr React: https://github.com/haoxins/react-flatpickr
 */
const DateTimePicker = ({
  date,
  onChange,
  enableTime = true,
  options = {},
  ...rest
}) => (
  <>
    <OveriddenFlatpickrStyles />
    <div style={{ display: 'flex' }}>
      <Flatpickr
        data-enable-time={enableTime}
        value={date}
        style={{ boxSizing: 'border-box' }}
        onChange={onChange}
        options={{
          dateFormat: 'J M Y H:i',
          plugins: [
            confirmDatePlugin({
              confirmText: 'Apply',
              confirmIcon: '',
              theme: 'dark'
            })
          ],
          ...options
        }}
        {...rest}
      />
    </div>
  </>
);

DateTimePicker.propTypes = {
  date: PropTypes.oneOfType([
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.object,
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  enableTime: PropTypes.bool,
  options: PropTypes.object
};

// appearance none fixes datepicker on ios
const StyledDateTimePicker = styled(DateTimePicker)`
  ${inputStyles}
  ${tw`w-full`}
  box-sizing: border-box;
  appearance: none;
`;

export default StyledDateTimePicker;
