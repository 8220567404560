import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { motion } from '@ubisend/framer-motion';

import emptyStatePlaceholder from './EmptyState';
import { Paragraph } from '../Text/index';

const StyledLink = styled(Link)`
  ${tw`hover:underline`}
  color: ${({ theme }) => theme.grey};
  font-size: 0.875rem;
  margin-left: 0.5rem;
`;

const EmptyStatePlaceholderContainer = styled.div`
  ${tw`flex flex-col`}

  margin: 2rem auto;

  height: ${props => (props.customHeight ? props.customHeight : null)};
`;

const SvgContainer = styled.div`
  ${tw`mx-auto`};
  svg {
    ${tw`mx-auto`};
    max-height: 199px;
  }
`;

const Container = styled(motion.div)`
  ${tw`mx-auto flex items-center flex-col`};
  max-width: 38rem;
  margin: 1rem auto 0 auto;
`;

const HeaderContainer = styled.div`
  ${tw`textCenter`}
  > h2 {
    margin: 0 auto;
  }
`;

const StyledParagraph = styled(Paragraph)`
  max-width: 38rem;
  margin-top: 1rem;
`;

const ActionsContainer = styled.div`
  margin: 1rem;
`;

const HelpContainer = styled.div`
  ${tw`mx-auto flex-row`};
  color: ${({ theme }) => theme.grey};
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const EmptyStateGraphic = ({ type }) => {
  const PlaceholderGraphic = emptyStatePlaceholder[type];
  if (typeof PlaceholderGraphic === 'undefined') {
    return null;
  }
  return (
    <PlaceholderGraphic
      transition={{ ease: [0.16, 1, 0.3, 1], duration: 1 }}
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      type={type}
    />
  );
};

const EmptyStatePlaceholder = ({
  heading,
  text,
  helpLink,
  helpText,
  type,
  actions,
  ...props
}) => {
  return (
    <EmptyStatePlaceholderContainer {...props}>
      <SvgContainer>
        <EmptyStateGraphic type={type} />
      </SvgContainer>
      <Container
        transition={{ delay: 0.2, ease: [0.16, 1, 0.3, 1], duration: 1 }}
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}>
        <HeaderContainer>{heading && <h2>{heading}</h2>}</HeaderContainer>
        {text && (
          <StyledParagraph textCenter secondary>
            {text}
          </StyledParagraph>
        )}
        <ActionsContainer>{actions && <>{actions}</>}</ActionsContainer>

        {helpLink && helpText && (
          <HelpContainer>
            Need help?
            <StyledLink to={helpLink}>{helpText}</StyledLink>
          </HelpContainer>
        )}
      </Container>
    </EmptyStatePlaceholderContainer>
  );
};

EmptyStateGraphic.propTypes = {
  type: PropTypes.string.isRequired
};

EmptyStatePlaceholder.propTypes = {
  type: PropTypes.string.isRequired,
  heading: PropTypes.string,
  text: PropTypes.string,
  helpLink: PropTypes.string,
  helpText: PropTypes.string,
  actions: PropTypes.element
};

export default EmptyStatePlaceholder;
