import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from '@ubisend/pulse-hooks';
import {
  Label,
  PageWrapper,
  Grid,
  Placeholder,
  StretchPanel,
  Divider,
  Flex,
  Paragraph,
  Pagination,
  useNotification,
  useModal,
  Tag,
  InnerPanel,
  ActionBar,
  Button,
  usePaginationReducer,
  useFilterReducer,
  FilterMenu,
  TextInput,
  EmptyStatePlaceholder
} from '@ubisend/pulse-components';
import { GroupSelect } from '@ubisend/pulse-groups';
import { PermissionFilter } from '@ubisend/pulse-auth';

import { CreateConversation } from './Components/index';
import {
  deleteConversation as deleteConversationApi,
  toggleConversationLock as toggleConversationLockApi
} from './api/index';

const Description = styled(Paragraph)`
  ${tw`flex-grow opacity-50`}
  /* stylelint-disable */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  /* stylelint-enable*/
  overflow: hidden;
`;

const Title = styled.h3`
  ${tw`truncate m-0`}
`;

const FooterSection = styled.div`
  ${tw`w-full flex items-center justify-center p-4`}
  margin: -1rem 0;
  &:not(:last-child) {
    ${tw` border-0 border-r border-solid border-grey`}
  }
  &:first-child {
    margin-left: -1rem;
  }
  &:last-child {
    margin-right: -1rem;
  }
`;

const Conversations = () => {
  const pagination = usePaginationReducer({ id: 'conversations' });
  const filters = useFilterReducer({
    id: 'conversations',
    initialFilters: { name: '', groups: [] },
    options: { pagination }
  });

  const { showSuccess } = useNotification();
  const { showModal, hideModal } = useModal();

  const queryClient = useQueryClient();
  const query = useQuery([
    'conversations/builder',
    { ...pagination.params, ...filters.form }
  ]);
  const deleteConversation = useMutation(deleteConversationApi, {
    onSuccess: () => {
      showSuccess('Successfully deleted workflow');
      queryClient.invalidateQueries('conversations/builder');
    }
  });
  const toggleConversationLock = useMutation(toggleConversationLockApi, {
    onSuccess: ({ data }) => {
      showSuccess(
        `Successfully ${data.data.locked ? '' : 'un'}locked workflow`
      );
      queryClient.invalidateQueries('conversations/builder');
    }
  });

  const handleGroupsChange = groups => {
    filters.updateFilters({
      groups: groups ? groups.map(group => group.value) : []
    });
  };

  const handleNameChange = event => {
    const name = event.target.value;

    filters.updateFilters({ name });
  };

  const handleConversationLock = conversation => {
    toggleConversationLock.mutate({
      id: conversation.id,
      locked: !conversation.locked
    });
  };

  const handleConversationDelete = conversation => {
    showModal({
      header: 'Delete workflow',
      body: `Are you sure you want to delete the "${conversation.title}" workflow?`,
      handleConfirm: async () => {
        try {
          await deleteConversation.mutateAsync(conversation.id);
        } finally {
          hideModal();
        }
      }
    });
  };

  const handleGroupClick = group => {
    filters.applyFilters({
      groups: filters.filters.groups.concat(group.id)
    });
  };

  return (
    <PageWrapper
      header="Workflows"
      subheader=" Create and manage your workflows"
      actions={
        <Flex xSpace>
          <FilterMenu
            position={FilterMenu.POSITIONS.LEFT}
            buttonProps={{
              loading: query.isLoading
            }}
            {...filters.props}>
            <Flex style={{ width: '20rem' }} col>
              <Flex col fat mb>
                <Label htmlFor="name">Name</Label>
                <TextInput
                  id="name"
                  placeholder="Workflows name"
                  value={filters.filters.name}
                  onChange={handleNameChange}
                />
              </Flex>
              <Flex col fat>
                <Label htmlFor="tags">Tags</Label>
                <GroupSelect
                  id="tags"
                  placeholder="Filter workflows by tag"
                  for="conversations"
                  value={filters.filters.groups}
                  onChange={handleGroupsChange}
                />
              </Flex>
            </Flex>
          </FilterMenu>
          {query.isSuccess && !query.showNoResultsMessage && (
            <PermissionFilter can="create conversations">
              <CreateConversation />
            </PermissionFilter>
          )}
        </Flex>
      }>
      <StretchPanel>
        <Flex pad fat col>
          {query.showNoResultsMessage && (
            <Flex fat center>
              <EmptyStatePlaceholder
                type="builder"
                heading="Create your first workflow"
                text="Build slick, user-focused, and efficient automation workflows."
                actions={
                  <PermissionFilter can="create conversations">
                    <CreateConversation />
                  </PermissionFilter>
                }
                helpLink="/docs/2151317744/2151842290"
                helpText="Learn more about workflows."
              />
            </Flex>
          )}
          <Grid columns={3} style={{ width: '100%' }}>
            {query.isLoading &&
              [0, 1, 2, 3, 4, 5].map(i => (
                <InnerPanel key={i}>
                  <Placeholder items={1} />
                  <Divider fullWidth />
                  <Flex between>
                    {[0, 1, 2].map(i => (
                      <FooterSection key={i}>
                        <Placeholder items={1} subitems={1} />
                      </FooterSection>
                    ))}
                  </Flex>
                </InnerPanel>
              ))}
            {query.showTable &&
              query.isSuccess &&
              query.data.data.map(conversation => (
                <InnerPanel key={conversation.id}>
                  <Flex col tall>
                    <Flex col ySpaceSm grow>
                      {conversation.groups.length > 0 && (
                        <Flex fat xSpaceSm>
                          {conversation.groups.map(group => (
                            <Button
                              variant="inline"
                              style={{ padding: 1 }}
                              key={group.id}
                              onClick={() => handleGroupClick(group)}>
                              <Tag>{group.name}</Tag>
                            </Button>
                          ))}
                        </Flex>
                      )}
                      <Flex col grow>
                        <Title>{conversation.title}</Title>
                        <Description>{conversation.description}</Description>
                      </Flex>
                    </Flex>
                    <Divider fullWidth />
                    <ActionBar shiftUp shiftLeft shiftDown shiftRight>
                      <Button
                        data-testid={`view-conversation-${conversation.id}`}
                        as={Link}
                        to={
                          conversation.locked
                            ? '#'
                            : `/builder/${conversation.id}`
                        }
                        variant="inline"
                        icon="eye"
                        middle
                        disabled={conversation.locked}
                        pad>
                        View
                      </Button>
                      <Button
                        data-testid={`lock-conversation-${conversation.id}`}
                        onClick={() => handleConversationLock(conversation)}
                        icon={`lock${conversation.locked ? 'Closed' : 'Open'}`}
                        variant="inline"
                        middle
                        pad>
                        {conversation.locked ? 'Unlock' : 'Lock'}
                      </Button>
                      <Button
                        data-testid={`delete-conversation-${conversation.id}`}
                        variant="inline"
                        middle
                        pad
                        onClick={() => handleConversationDelete(conversation)}
                        disabled={conversation.locked}
                        colour="danger"
                        icon="trash">
                        Delete
                      </Button>
                    </ActionBar>
                  </Flex>
                </InnerPanel>
              ))}
          </Grid>
        </Flex>
        {query.data && query.showTable && query.showPagination && (
          <>
            <Flex fat col>
              <Divider mbNone mtNone />
              <Pagination pagination={query.data.meta} {...pagination.props} />
            </Flex>
          </>
        )}
      </StretchPanel>
    </PageWrapper>
  );
};

export default Conversations;
