import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { pseudoStyles } from '../Input/TextInput';
import { Panel, InnerPanel } from '../Panel/index';
import Divider from '../Spacing/Divider';
import Placeholder from '../Loading/Placeholder';
import Label from '../Forms/Label';
import Flex from '../Layout/Flex';
import { MiniToggle } from '../Toggle';

const AccordionHeader = styled.button`
  ${tw`flex justify-between items-center cursor-pointer bg-white p-4 rounded border-0`}
  margin: -1rem;
  width: calc(100% + 2rem);
  border: 1px solid transparent;
  & * {
    ${tw`cursor-pointer`}
  }
  & div {
    ${tw`flex`}
  }
  ${pseudoStyles}
`;

const AccordionContent = styled.div`
  ${tw`p-4 pt-0`}
  margin: -1rem;
`;

const StyledInnerPanel = styled(InnerPanel)`
  border: 1px solid
    ${props => (props.checked ? props.theme.primary : tw`border-grey-medium`)};
`;

const ToggleAccordion = ({
  children,
  header,
  actions,
  inner = true,
  disabled = false,
  loading = false,
  toggleOnly,
  primary,
  checked,
  on,
  onChange,
  ...props
}) => {
  const [collapsed, setCollapsed] = useState();

  const handleClick = () => {
    setCollapsed(!collapsed);
    onChange();
  };

  useEffect(() => {
    setCollapsed(on);
  }, [on]);

  const Wrapper = inner ? StyledInnerPanel : Panel;

  const hasChildren = React.Children.count(children) > 0;

  return (
    <Wrapper checked={checked}>
      {loading ? (
        <Placeholder />
      ) : (
        <>
          <AccordionHeader
            {...props}
            disabled={disabled}
            onClick={handleClick}
            aria-expanded={!collapsed}
            type="button">
            <Label mb={false}>{header}</Label>
            <Flex xSpace>
              {actions}
              {!disabled && <MiniToggle primary={primary} on={checked} />}
            </Flex>
          </AccordionHeader>
          {checked && hasChildren && (
            <AccordionContent>
              <Divider fullWidth />
              {children}
            </AccordionContent>
          )}
        </>
      )}
    </Wrapper>
  );
};

ToggleAccordion.propTypes = {
  header: PropTypes.element,
  actions: PropTypes.element,
  stretch: PropTypes.bool,
  inner: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  toggleOnly: PropTypes.bool,
  primary: PropTypes.bool,
  checked: PropTypes.bool,
  on: PropTypes.bool,
  onChange: PropTypes.func
};

export default ToggleAccordion;
