import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chroma from 'chroma-js';

import {
  Flex,
  FormGroup,
  Label,
  TextInput,
  Button,
  Span
} from '@ubisend/pulse-components';
import { SheetSelect } from '@ubisend/pulse-files';

const StyledBox = styled.label`
  ${tw`w-full py-8 px-8 border-grey-medium border-dashed border cursor-pointer`}
  &:not(:disabled):hover {
    background: ${({ theme }) => {
      return chroma.mix('white', theme.primary, 0.125);
    }};
  }
  &:not(:disabled):active {
    background: ${({ theme }) => {
      return chroma.mix('white', theme.primary, 0.25);
    }};
  }
`;

const SheetModal = ({
  handleSubmit,
  loading,
  initialName = '',
  initialFile = null
}) => {
  const [name, setName] = useState(initialName);
  const [file, setFile] = useState(initialFile);
  const [chooseSheet, setChooseSheet] = useState(false);

  const handleNameChange = event => {
    setName(event.target.value);
  };

  const handleFileChange = file => {
    setFile(file);
    setChooseSheet(false);
  };

  const valid = useMemo(() => {
    return Boolean(name && file);
  }, [name, file]);

  const onSubmit = event => {
    event.preventDefault();

    if (valid && !loading) {
      handleSubmit({
        name,
        file_id: file.id,
        source_id: file.source.id
      });
    }
  };

  return (
    <>
      {chooseSheet && (
        <SheetSelect
          handleFileSelect={handleFileChange}
          handleCancel={() => setChooseSheet(false)}
        />
      )}
      <form onSubmit={onSubmit}>
        <FormGroup>
          <Label htmlFor="name">Spreadsheet integration name</Label>
          <Flex xSpace>
            <TextInput
              id="name"
              placeholder="List of FAQs"
              value={name}
              onChange={handleNameChange}
            />
          </Flex>
        </FormGroup>
        <Flex fat mb>
          <StyledBox
            role="button"
            aria-label="Select a spreadsheet"
            onClick={() => setChooseSheet(true)}>
            <Flex col ySpaceSm>
              <Span>{file ? file.name : '+ Select a spreadsheet'}</Span>
              {file && (
                <Span tinyText light>
                  Stored in {file.source.name}
                </Span>
              )}
            </Flex>
          </StyledBox>
        </Flex>
        <Flex right>
          <Button
            type="submit"
            loading={loading}
            disabled={!valid || loading}
            variant="primary">
            Save
          </Button>
        </Flex>
      </form>
    </>
  );
};

SheetModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  initialName: PropTypes.string,
  initialFile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    source: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  })
};

export default SheetModal;
