import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { motion } from '@ubisend/framer-motion';

import { useBot } from '../../../../hooks/index';
import CloseButton from './CloseButton';

const CallToActionContainer = styled(motion.div)`
  ${tw`absolute`}
  bottom: ${props => props.bottomOffset}px;
  right: 90px;
  max-width: 226px;
  ${props => `background-color:${props.theme.bot.ctaBackgroundColour};`}
  ${tw`z-1 text-white text-left border-none flex items-center justify-center shadow rounded-lg cursor-pointer`}
  padding: 12px 32px 12px 12px;
  &::before {
    ${tw`block absolute`}
    width: 16px;
    height: 16px;
    ${props => props.theme.bot.ctaBackgroundColour}
    content:'';
    transform: rotate(45deg);
    bottom: -6.4px;
    right: 24px;
  }
`;

const CallToActionButton = styled(motion.button)`
  background-color: transparent;
  ${props => `font-family: '${props.theme.bot.bodyFont}', sans-serif;`}
  ${tw`z-1 text-white text-left border-none cursor-pointer p-0`} 
  font-size: 16px;
`;

// How many pixels off the bottom of the screen the cta should be.
const bottomOffset = 18;

const CallToAction = props => {
  const { ctaText, setShowCta, onCtaShow } = useBot();

  const ref = useRef(null);

  useEffect(() => {
    if (!onCtaShow) {
      return;
    }

    if (ref.current) {
      onCtaShow({ height: ref.current.offsetHeight + bottomOffset });
    }
  }, [ref, onCtaShow]);

  return (
    <>
      <CallToActionContainer
        bottomOffset={bottomOffset}
        whileHover={{ scale: 1.0375 }}
        transition={{ type: 'spring', stiffness: 300, damping: 10 }}
        {...props}>
        <CallToActionButton
          ref={ref}
          id="open-widget-cta"
          onTouchStart={() => setShowCta(false)}
          whileTap={{ scale: 0.95 }}
          {...props}>
          {ctaText}
        </CallToActionButton>
        <CloseButton />
      </CallToActionContainer>
    </>
  );
};

export default CallToAction;
